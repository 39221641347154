import React from 'react'

import dogWallLogo from '../assets/images/black-alsatian-wall.svg'

const BannerPortfolio = props => (
  <section id="banner" className="style4">
    <div className="inner">
      <div className="image">
        <img
          src={dogWallLogo}
          alt="Black Alsatian Web Design Logo"
          className="dog-wall-svg"
        />
      </div>
      <header className="major">
        <h1>Some Of Our Web Designs.</h1>
      </header>
      <div className="content">
        <p>[just an appetizer]</p>
      </div>
    </div>
  </section>
)

export default BannerPortfolio
