import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import BannerPortfolio from '../components/BannerPortfolio'
import SEO from '../components/seo'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

class Portfolio extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout>
        <SEO
          title='Our website design portfolio'
          description='A portfolio from our little web design company. We are proud of our work and love showing off our websites!'
          keywords={['web design', 'web development', 'online marketing']}
        />

        <BannerPortfolio />

        <div id='main'>
          <section id='one'>
            <div className='inner'>
              <header className='major'>
                <h2>Our Websites Are Worth Showing Off.</h2>
              </header>
              <p>
                Don't take our word for it. Ask our many happy long-standing
                website clients.
              </p>
              <p>
                We’ve developed websites for a long time, and many of our
                clients have stuck with us from the beginning. We upgrade their
                websites, build their new websites, and develop their
                Progressive Web Apps.
              </p>
              <p>Why would they need any other web design company?</p>
              <p>
                Below is a showcase of just a few of the kinds of websites we
                can develop for you.
              </p>
              <p>
                When you join the Black Alsatian family, you don't just buy a
                website from a web design company.
              </p>
              <p>
                You partner up with web development professionals that have it
                down to a science.
              </p>
            </div>
          </section>
          <section id='two' className='spotlights'>
            <section>
              <a
                href='http://www.adfsa.com/'
                className='image'
                title='ADF Outdoor'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Visit Website'
              >
                <Img fluid={data.adfsa.childImageSharp.fluid} alt='' />
              </a>
              <div className='content'>
                <div className='inner'>
                  <header className='major'>
                    <h3>ADF Outdoor (Pty) Ltd</h3>
                  </header>
                  <p>ADFSA's website is a Joomla Content Management System.</p>
                  <p>
                    The site was initially developed to connect to the
                    supplier's API, to populate the product catalogue.
                  </p>
                  <p>
                    They have since done away with the API connection, and we
                    created a product catalogue which the client now updates
                    themselves, using an import tool that uploads Excel files.
                  </p>
                  <p>
                    This client handles all aspects of their own website, from
                    updates to website layouts and extensions.
                  </p>
                  <ul className='actions'>
                    <li>
                      <a
                        href='http://www.adfsa.com'
                        className='button special'
                        title='ADF Outdoor'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='Visit Website'
                      >
                        View Website
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a
                href='https://www.malamuleleonward.org'
                className='image'
                title='Malamulele Onwards'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Visit Website'
              >
                <Img fluid={data.malamulele.childImageSharp.fluid} alt='' />
              </a>
              <div className='content'>
                <div className='inner'>
                  <header className='major'>
                    <h3>Malamulele Onward</h3>
                  </header>
                  <p>
                    Malamulele Onward is a Joomla CMS where the client handles
                    all updates themselves.
                  </p>
                  <p>
                    This website has a custom-developed component for accepting
                    donations and allowing visitors to create their own
                    campaigns.
                  </p>
                  <ul className='actions'>
                    <li>
                      <a
                        href='https://www.malamuleleonward.org/'
                        className='button special'
                        title='Malamulele Onwards'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='Visit Website'
                      >
                        View Website
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a
                href='https://www.solarconnect.co.za/solar-savings-calculator'
                className='image'
                title='Solar Connect'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Visit Website'
              >
                <Img fluid={data.solarConnect.childImageSharp.fluid} alt='' />
              </a>
              <div className='content'>
                <div className='inner'>
                  <header className='major'>
                    <h3>SolarConnect</h3>
                  </header>
                  <p>
                    We built the SolarConnect website as a lead generation tool,
                    to connect the people in South Africa with reliable solar
                    panel providers in their area.
                  </p>
                  <p>
                    The Solar Savings Calculator is a tool created using AJAX
                    technologies, which allows users to see the kind of savings
                    they can expect by switching to solar power.
                  </p>
                  <p>
                    Click the button below and visit the website to try it out
                    yourself.
                  </p>
                  <ul className='actions'>
                    <li>
                      <a
                        href='https://www.solarconnect.co.za/solar-savings-calculator'
                        className='button special'
                        title='Solar Connect'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='Visit Website'
                      >
                        View Website
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a
                href='http://www.pirateslodge.co.za'
                className='image'
                title='Pirates Lodge'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Visit Website'
              >
                <Img fluid={data.piratesLodge.childImageSharp.fluid} alt='' />
              </a>
              <div className='content'>
                <div className='inner'>
                  <header className='major'>
                    <h3>Pirates Lodge</h3>
                  </header>
                  <p>
                    Pirates Lodge is one of our first ever web design clients.
                  </p>
                  <p>
                    The first installation of their website was a
                    custom-developed Content Management System.
                  </p>
                  <p>
                    Since then, the company has changed hands. When the time
                    came to upgrade, they wanted a simple one-page website.
                  </p>
                  <p>
                    As we try to cater for all our different website clients, we
                    built them a site to be proud of.
                  </p>
                  <p>View our various web design packages at the link below.</p>
                  <ul className='actions'>
                    <li>
                      <a
                        href='http://www.pirateslodge.co.za'
                        className='button special'
                        title='Pirates Lodge'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='Visit Website'
                      >
                        View Website
                      </a>
                    </li>
                    <li>
                      <OutboundLink
                        href='https://www.blackalsatian.co.za/services/website-design/'
                        className='button'
                        title='Website Packages'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='Visit Website'
                      >
                        Website Packages
                      </OutboundLink>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a
                href='http://www.longboardingsa.co.za'
                className='image'
                title='Lonboarding SA'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Visit Website'
              >
                <Img fluid={data.longboardingSa.childImageSharp.fluid} alt='' />
              </a>
              <div className='content'>
                <div className='inner'>
                  <header className='major'>
                    <h3>Longboarding SA</h3>
                  </header>
                  <p>
                    The Longboarding SA website was originally developed out of
                    a love for the sport.
                  </p>
                  <p>
                    At the time, longboarding was having a growing spurt and
                    there just weren't many websites where one could purchase
                    longboarding gear.
                  </p>
                  <p>
                    Initially the website was just going to be a forum for
                    longboarders to connect, but eventually it grew into a
                    full-blown e-commerce website.
                  </p>
                  <p>
                    Since then we sold the website and the business attached to
                    it.
                  </p>
                  <p>
                    This website is an example of a Joomla CMS website with a
                    shopping cart built onto it. The owner manages the entire
                    website themselves.
                  </p>
                  <ul className='actions'>
                    <li>
                      <a
                        href='http://www.longboardingsa.co.za'
                        className='button special'
                        title='Lonboarding SA'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='Visit Website'
                      >
                        View Website
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section>
              <a
                href='http://www.thebitcoinminersclub.co.za'
                className='image'
                title='The Bitcoin Miners Club'
                target='_blank'
                rel='noopener noreferrer'
                aria-label='Visit Website'
              >
                <Img fluid={data.bitcoin.childImageSharp.fluid} alt='' />
              </a>
              <div className='content'>
                <div className='inner'>
                  <header className='major'>
                    <h3>The Bitcoin Miners Club</h3>
                  </header>
                  <p>
                    The Bitcoin Miners Club website is an example of what can be
                    achieved with a proper SEO strategy, with an email marketing
                    funnel attached to it.
                  </p>
                  <p>
                    Since the website's inception, the focus was on Search
                    Engine Optimisation. We crafted a lead magnet which is given
                    away for free when users sign up.
                  </p>
                  <p>
                    After signing up on the website, visitors go into an email
                    funnel which educates them about Bitcoin and Bitcoin Mining.
                    The funnel also warns them about the many pitfalls when
                    getting involved with cryptocurrency.
                  </p>
                  <ul className='actions'>
                    <li>
                      <a
                        href='http://www.thebitcoinminersclub.co.za'
                        className='button special'
                        title='The Bitcoin Miners Club'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='Visit Website'
                      >
                        View Website
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </section>
          <div className='inner'>
            <p>
              We can build any kind of website your heart desires. Coupled with
              our digital marketing skills, we are able and committed to taking
              your business to the next level.
            </p>
            <p>
              To view our full web development portfolio, be sure to visit our
              South African website at the link below.
            </p>
            <ul className='actions'>
              <li>
                <OutboundLink
                  href='https://www.blackalsatian.co.za/portfolio/'
                  className='button special'
                  title='Website Development portfolio'
                  target='_blank'
                  rel='noopener noreferrer'
                  aria-label='Visit Website'
                >
                  Website Portfolio
                </OutboundLink>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Portfolio

export const query = graphql`
  query {
    adfsa: file(relativePath: { eq: "adfsa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allPaint: file(relativePath: { eq: "all-paint-projects.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bitcoin: file(relativePath: { eq: "bitcoin-miners-club.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    longboardingSa: file(relativePath: { eq: "longboarding-sa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    malamulele: file(relativePath: { eq: "malamulele.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    solarConnect: file(relativePath: { eq: "solarconnect.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    piratesLodge: file(relativePath: { eq: "pirates-lodge.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
